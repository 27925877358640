:global .toast-notification {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 999; }

:global .toast-notice-wrapper.notice-enter {
  opacity: 0.01;
  transform: scale(0); }

:global .toast-notice-wrapper.notice-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms ease-out; }

:global .toast-notice-wrapper.notice-exit {
  opacity: 1;
  transform: translateY(0); }

:global .toast-notice-wrapper.notice-exit-active {
  opacity: 0.01;
  transform: translateY(-40%);
  transition: all 300ms ease-out; }

:global .toast-notice {
  background: rgba(0, 0, 0, 0.5);
  padding: 0.15rem 0.3rem;
  margin: 0.08rem auto;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.06rem;
  color: #fff;
  font-size: 0.26rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1rem; }

:global .toast-notice > div {
  max-width: 4rem;
  text-align: justify; }

:global .toast-notice > img {
  display: block; }

@keyframes rotating {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }
